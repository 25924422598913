import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

// ## Generator Reducer Imports
import auth from "../modules/auth/redux/reducers/AuthReducer";
import user from "../modules/auth/redux/reducers/UserReducer";
import addMemory from "../modules/add_memory/redux/reducers/AddMemoryReducer";
import editProfile from "../modules/account/edit_profile/redux/reducers/EditProfileReducer";
import mediaPicker from "../modules/media_picker/redux/reducers/MediaPickerReducers";
import general from "./general/reducers/GeneralReducers";
import spotify from "../modules/auth/redux/reducers/SpotifyReducer";
import notification from "../modules/auth/redux/reducers/NotificationReducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "user"],
};

export default persistReducer(
  persistConfig,
  combineReducers({
    // ## Generator Reducers
    auth,
    user,
    addMemory,
    editProfile,
    mediaPicker,
    general,
    spotify,
    notification,
  })
);

function getNormalConfig(name, blackList) {
  return {
    key: name,
    storage: storage,
    blacklist: blackList,
  };
}
