import * as ACTION_TYPES from '../actions/ActionTypes'

const INITIAL_STATE = {
  isLoading: false,
  success: false,
  error: null,
  user: null,
  authStrategies: null,
  token: null,
  currentAction: null,
  isRequestPermission: true,
  isPendingReviewCalled: false,
}

export default (state = INITIAL_STATE, action) => {
  switch (action?.type) {
    case ACTION_TYPES.GET_ME:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: null,
        currentAction: action?.type,
      }
    case ACTION_TYPES.GET_ME_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        error: null,
        user: action?.data?.data ? action?.data?.data : null,
        currentAction: action?.type,
      }
    case ACTION_TYPES.GET_ME_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: 'An error occured',
        success: false,
        currentAction: action.type,
      }

    case ACTION_TYPES.UPDATE_USER:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: null,
        currentAction: action.type,
      }
    case ACTION_TYPES.UPDATE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        error: null,
        currentAction: action.type,
      }
    case ACTION_TYPES.UPDATE_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: 'An error occured',
        success: false,
        currentAction: action.type,
      }

    case ACTION_TYPES.UPDATE_PASSWORD:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: null,
        currentAction: action.type,
      }
    case ACTION_TYPES.UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        error: null,
        currentAction: action.type,
      }
    case ACTION_TYPES.UPDATE_PASSWORD_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.data.message,
        success: false,
        currentAction: action.type,
      }

    case ACTION_TYPES.FORGOT_PASSWORD:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: null,
        currentAction: action.type,
      }
    case ACTION_TYPES.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        error: null,
        currentAction: action.type,
      }
    case ACTION_TYPES.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.data.message,
        success: false,
        currentAction: action.type,
      }

    case ACTION_TYPES.AUTH_UNAUTHORIZED:
    case ACTION_TYPES.LOGOUT:
      return INITIAL_STATE

    case ACTION_TYPES.REQUEST_NOTIFICATION_PERMISSION:
      return { ...state, isRequestPermission: false }

    case ACTION_TYPES.REQUEST_PENDING_REVIEW:
      return { ...state, isPendingReviewCalled: true }

    case ACTION_TYPES.USER_PROFILE_IMAGE_UPLOAD:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: null,
        currentAction: action.type,
      }
    case ACTION_TYPES.USER_PROFILE_IMAGE_UPLOAD_SUCCESS:
      let { user } = state
      if (user) {
        user = { ...user, avatar: action.data.avatar }
      }
      return {
        ...state,
        isLoading: false,
        success: false,
        error: null,
        user,
        currentAction: action.type,
      }
    case ACTION_TYPES.USER_PROFILE_IMAGE_UPLOAD_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: 'An error occured',
        success: false,
        currentAction: action.type,
      }

    default:
      return state
  }
}
