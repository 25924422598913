export default {
  primaryColor: "#161616",
  green: "#34aa47",
  orange: "#ff5722",
  lightGreen: "#00ea81",
  gray: "#ffffff20",
  black: "#161616",
  black2: "#222",
  black3: "#A2A2A2",
  spliterLine: "#414141",
  white: "#FFFFFF",
  placeholder: "#939393",
  whiteText: "#FFFFFFCC",
  whiteHalf: "#FFFFFF80",
  grayBackground: "#282828",
  grayBackground2: "#1B1818",
  grayText: "#535353",
  grayText2: "#939393",
  darkGray: "#A4A4AF",
  lightGray: "#DBDBDB",
  tabBottomColor: "#343434",
  barTansparentBg: "#FFFFFF28",
  white5: "#FFFFFF0C",
  white60: "#FFFFFF78",
  white20: "#FFFFFF28",
  white10: "#FFFFFF19",
  white30: "#FFFFFF4C",
  white70: "#FFFFFFB2",
  white80: "#FFFFFFCC",
  white50: "#FFFFFF80",
  white40: "#FFFFFF50",
  grayBorder: "#676767",
  grey: "#676767",
  grey2: "#757373",
  gradient1: "#F526D6",
  gradient2: "#F526D6",
  gradient3: "#44B3F8",
  pink: "#F526D6",
  blue: "#44B3F8",
  purple: "#A408FE",
  topBarColor: "#252525",
  darkGrayCard: "#282828",
  red: "red",
};
