import * as ACTION_TYPES from '../actions/ActionTypes'
const INITIAL_STATE = {
  festivalGoingList: [],
  festivalSaveList: [],
  artistFollowList: [],
  userFollowList: [],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPES.FESTIVAL_GOING_LIST:
      return { ...state, festivalGoingList: action.payload }
    case ACTION_TYPES.FESTIVAL_SAVE_LIST:
      return { ...state, festivalSaveList: action.payload }
    case ACTION_TYPES.ARTIST_FOLLOW_LIST:
      return { ...state, artistFollowList: action.payload }
    case ACTION_TYPES.USER_FOLLOW_LIST:
      return { ...state, userFollowList: action.payload }
    default:
      return state
  }
}
