import * as ACTION_TYPES from '../actions/ActionTypes'
const INITIAL_STATE = {
  formData: {
    avatar: null,
    coverImage: null,
    bio: '',
    location: '',
    email: '',
    phone: '',
    website: '',
  },
  socialMediaLinks: [],
}

export default (state = INITIAL_STATE, action) => {
  let newSocialMediaLinks = []
  switch (action.type) {
    case ACTION_TYPES.SET_PROFILE_FORM_DATA:
      const data = {
        ...state.formData,
        ...action.payload,
      }
      return { ...state, formData: data }
    case ACTION_TYPES.SET_SOCIAL_MEDIA_LINKS:
      return { ...state, socialMediaLinks: action.payload }
    case ACTION_TYPES.ADD_SOCIAL_MEDIA_LINK:
      newSocialMediaLinks = [...state.socialMediaLinks, action.payload]
      return { ...state, socialMediaLinks: newSocialMediaLinks }
    case ACTION_TYPES.DELETE_SOCIAL_MEDIA_LINK:
      newSocialMediaLinks = state.socialMediaLinks.filter(
        (x) => x.id != action.id
      )
      return { ...state, socialMediaLinks: newSocialMediaLinks }
    case ACTION_TYPES.UPDATE_SOCIAL_MEDIA_LINK:
      let newSocialMediaLinks = [...state.socialMediaLinks]
      const index = newSocialMediaLinks.findIndex((x) => x.id == action.id)
      newSocialMediaLinks[index] = action.payload
      return { ...state, socialMediaLinks: newSocialMediaLinks }
    case ACTION_TYPES.LOGOUT:
      return INITIAL_STATE
    default:
      return state
  }
}
