export const AUTH = 'AUTH'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_FAILURE = 'AUTH_FAILURE'
export const AUTH_UNAUTHORIZED = 'AUTH_UNAUTHORIZED'

export const SOCIAL_AUTH_CONNECT = 'SOCIAL_AUTH_CONNECT'
export const SOCIAL_AUTH_CONNECT_SUCCESS = 'SOCIAL_AUTH_CONNECT_SUCCESS'

export const EMAIL_VERIFY = 'EMAIL_VERIFY'
export const EMAIL_VERIFY_SUCCESS = 'EMAIL_VERIFY_SUCCESS'
export const EMAIL_VERIFY_FAILURE = 'EMAIL_VERIFY_FAILURE'

export const REGISTER = 'REGISTER'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAILURE = 'REGISTER_FAILURE'

export const INITIALIZE_ME = 'INITIALIZE_ME'
export const INITIALIZE_ME_SUCCESS = 'INITIALIZE_ME_SUCCESS'
export const INITIALIZE_ME_FAILURE = 'INITIALIZE_ME_FAILURE'

export const VISIT_WELCOME = 'VISIT_WELCOME'

export const LOGOUT = 'LOGOUT'

export const AUTH_STRATEGY_DESTROY_SUCCESS = 'AUTH_STRATEGY_DESTROY_SUCCESS'

export const GET_AUTH = 'GET_AUTH'

export const GET_ME = 'GET_ME'
export const GET_ME_SUCCESS = 'GET_ME_SUCCESS'
export const GET_ME_FAILURE = 'GET_ME_FAILURE'
export const SET_NAVIGATE_TO_MAIN = 'SET_NAVIGATE_TO_MAIN'

export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE'

export const UPDATE_PASSWORD = 'UPDATE_PASSWORD'
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS'
export const UPDATE_PASSWORD_FAILURE = 'UPDATE_PASSWORD_FAILURE'

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE'

export const SPOTIFY_SYNC_FOLLOWINGS = 'SPOTIFY_SYNC_FOLLOWINGS'
export const SPOTIFY_SYNC_FOLLOWINGS_SUCCESS = 'SPOTIFY_SYNC_FOLLOWINGS_SUCCESS'
export const SPOTIFY_SYNC_FOLLOWINGS_FAILURE = 'SPOTIFY_SYNC_FOLLOWINGS_FAILURE'

export const SPOTIFY_SYNC_FOLLOWINGS_CLOSE_ALERT =
  'SPOTIFY_SYNC_FOLLOWINGS_CLOSE_ALERT'

export const NOTIFICATION_FETCH_UNREAD_COUNT = 'NOTIFICATION_FETCH_UNREAD_COUNT'
export const NOTIFICATION_FETCH_UNREAD_COUNT_SUCCESS =
  'NOTIFICATION_FETCH_UNREAD_COUNT_SUCCESS'
export const NOTIFICATION_FETCH_UNREAD_COUNT_FAILURE =
  'NOTIFICATION_FETCH_UNREAD_COUNT_FAILURE'
export const NOTIFICATION_SET_UNREAD_COUNT = 'NOTIFICATION_SET_UNREAD_COUNT'
