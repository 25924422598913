import * as ACTION_TYPES from '../actions/ActionTypes'

const INITIAL_STATE = {
  isLoading: false,
  success: false,
  error: null,
  showAlert: false,
  message: '',
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPES.SPOTIFY_SYNC_FOLLOWINGS_CLOSE_ALERT:
      return {
        ...state,
        showAlert: false,
      }
    case ACTION_TYPES.SPOTIFY_SYNC_FOLLOWINGS:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: null,
        showAlert: false,
        message: '',
        currentAction: action.type,
      }
    case ACTION_TYPES.SPOTIFY_SYNC_FOLLOWINGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        showAlert: true,
        error: null,
        message: action.message,
        currentAction: action.type,
      }
    case ACTION_TYPES.SPOTIFY_SYNC_FOLLOWINGS_FAILURE:
      return {
        ...state,
        isLoading: false,
        success: false,
        showAlert: true,
        error: null,
        message: action.message,
        currentAction: action.type,
      }
    default:
      return state
  }
}
