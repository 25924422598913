import * as ACTION_TYPES from '../actions/ActionTypes'

const INITIAL_STATE = {
  isLoading: false,
  success: false,
  error: null,
  unreadCount: 0,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPES.NOTIFICATION_SET_UNREAD_COUNT:
      return {
        ...state,
        unreadCount: action.unreadCount,
      }
    case ACTION_TYPES.NOTIFICATION_FETCH_UNREAD_COUNT:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: null,
        currentAction: action.type,
      }
    case ACTION_TYPES.NOTIFICATION_FETCH_UNREAD_COUNT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        error: null,
        unreadCount: action.unreadCount,
        currentAction: action.type,
      }
    case ACTION_TYPES.NOTIFICATION_FETCH_UNREAD_COUNT_FAILURE:
      return {
        ...state,
        isLoading: false,
        success: false,
        error: null,
        currentAction: action.type,
      }
    default:
      return state
  }
}
