//api base urls

var developmentRelease = false
var productionDevelopment = false

var development = {
  baseURL: 'https://api-bt.soundclub.com/backend/v1/api',
  mapAPIKey: 'AIzaSyDzZpj4cB8ijdRVffPk0wV6BXdTlTrkiy0',
}

var production = {}

var staging = {}

export default development //(!productionDevelopment && (__DEV__ || developmentRelease)) ? staging : production
