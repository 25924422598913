import * as ACTION_TYPES from '../actions/ActionTypes'
const INITIAL_STATE = {
  isLoading: false,
  success: false,
  medias: [],
  addMemoryFormData: {
    title: '',
    description: '',
    visitDate: null,
    artistTags: [],
  },
  ratings: [],
  advancedSettings: {
    isPostOnTwitter: false,
    isPostOnFacebook: false,
    isPostOnInstagram: false,
    replyType: 1,
  },
}

export default (state = INITIAL_STATE, action) => {
  let medias = []
  switch (action.type) {
    case ACTION_TYPES.SET_ADD_MEMORY_FORM_DATA:
      const addMemoryFormData = {
        ...state.addMemoryFormData,
        ...action.payload,
      }
      return { ...state, addMemoryFormData }
    case ACTION_TYPES.SET_POST_ADVANCED_SETTIONGS:
      const advancedSettings = {
        ...state.advancedSettings,
        ...action.payload,
      }
      return { ...state, advancedSettings }
    case ACTION_TYPES.SET_ADD_MEMORY_RATINGS:
      return { ...state, ratings: action.ratings }
    case ACTION_TYPES.RESET_ADD_MEMORY:
      return { ...INITIAL_STATE, ratings: action.ratings }
    case ACTION_TYPES.ADD_MEMORY_ADD_MEDIA:
      medias = [...state.medias, ...action.medias]
      return { ...state, medias }
    case ACTION_TYPES.ADD_MEMORY_REMOVE_MEDIA:
      medias = state.medias.filter((x) => x._id != action.mediaId)
      return { ...state, medias }
    case ACTION_TYPES.CREATE_MEMORY:
      return { ...state, isLoading: true, success: false }
    case ACTION_TYPES.CREATE_MEMORY_FAILURE:
      return { ...state, isLoading: false, success: false }
    case ACTION_TYPES.CREATE_MEMORY_SUCCESS:
      return { ...state, isLoading: false, success: true }
    case ACTION_TYPES.CREATE_POST:
      return { ...state, isLoading: true, success: false }
    case ACTION_TYPES.CREATE_POST_FAILURE:
      return { ...state, isLoading: false, success: false }
    case ACTION_TYPES.CREATE_POST_SUCCESS:
      return { ...state, isLoading: false, success: true }
    default:
      return state
  }
}
