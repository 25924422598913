import storage from "redux-persist/lib/storage";
import { Constants } from "../config";
import * as DirectNavigation from "../modules/navigation/DirectNavigation";

export function saveCredential(credential) {
  storage.setItem(
    Constants.storageKey.STOREAGE_KEY_CREDENTICAL,
    JSON.stringify(credential)
  );
}
export function getCredential() {
  return storage.getItem(Constants.storageKey.STOREAGE_KEY_CREDENTICAL);
}
export function getLocalAuth() {
  return new Promise((resolve, reject) => {
    storage
      .getItem("auth")
      .then((authString) => {
        if (authString) {
          const auth = JSON.parse(authString);
          resolve(auth);
        }
        reject(null);
      })
      .catch((ex) => {
        reject(null);
      });
  });
}

export function removeAuth() {
  storage.removeItem("auth");
  DirectNavigation.reset("LoginScreen");
}
export function saveRememberMe(isRememberMe) {
  storage.setItem(Constants.storageKey.STOREAGE_KEY_REMEMBER_ME, isRememberMe);
}
export function getValue(key) {
  return storage.getItem(key);
}
export function saveValue(key, value) {
  return storage.setItem(key, value);
}
export function removeCredential() {
  storage.removeItem(Constants.storageKey.STOREAGE_KEY_CREDENTICAL);
}
