export const SET_ADD_MEMORY_FORM_DATA = 'SET_ADD_MEMORY_FORM_DATA'
export const SET_POST_ADVANCED_SETTIONGS = 'SET_POST_ADVANCED_SETTIONGS'
export const SET_ADD_MEMORY_RATINGS = 'SET_ADD_MEMORY_RATINGS'
export const RESET_ADD_MEMORY = 'RESET_ADD_MEMORY'
export const ADD_MEMORY_ADD_MEDIA = 'ADD_MEMORY_ADD_MEDIA'
export const ADD_MEMORY_REMOVE_MEDIA = 'ADD_MEMORY_REMOVE_MEDIA'

export const CREATE_MEMORY = 'CREATE_MEMORY'
export const CREATE_MEMORY_SUCCESS = 'CREATE_MEMORY_SUCCESS'
export const CREATE_MEMORY_FAILURE = 'CREATE_MEMORY_FAILURE'

export const CREATE_POST = 'CREATE_POST'
export const CREATE_POST_SUCCESS = 'CREATE_POST_SUCCESS'
export const CREATE_POST_FAILURE = 'CREATE_POST_FAILURE'
