import Images from "./Images";
import Values from "./Values";
export default {
  termsAndConditions: "http://soundclub.com/terms-and-conditions",
  privacyPolicy: "http://soundclub.com/privacy-policy",
  appVersion: "app_ver",
  appVersionIOS: "app_ver_ios",
  modalType: {
    MODAL_TYPE_CENTER: "MODAL_TYPE_CENTER",
    MODAL_TYPE_BOTTOM: "MODAL_TYPE_BOTTOM",
    MODAL_TYPE_FULL: "MODAL_TYPE_FULL",
  },
  mediaType: {
    MEDIA_TYPE_IMAGE: "image",
    MEDIA_TYPE_VIDEO: "video",
  },
  memoryType: {
    MEMORY_TYPE_VIBE: 1,
  },
  profileStatusType: {
    PROFILE_STATUS_SAVED: "PROFILE_STATUS_SAVED",
    PROFILE_STATUS_GOING: "PROFILE_STATUS_GOING",
    PROFILE_STATUS_CONNECTION: "PROFILE_STATUS_CONNECTION",
    PROFILE_STATUS_FOLLOWER: "PROFILE_STATUS_FOLLOWER",
    PROFILE_STATUS_FOLLOWING: "PROFILE_STATUS_FOLLOWING",
    PROFILE_STATUS_EVENT: "PROFILE_STATUS_EVENT",
  },
  profileConnectType: {
    PROFILE_CONNECT_GOING: 1,
    PROFILE_CONNECT_SAVE: 2,
    PROFILE_CONNECT_CONNECTION: 3,
    PROFILE_CONNECT_FOLLOW: 4,
  },
  profileConnectStatus: {
    PROFILE_CONNECT_STATUS_REQUEST: 1,
    PROFILE_CONNECT_STATUS_ACCEPT: 2,
  },
  buttonAction: {
    ACTION_SORT: "sort",
    ACTION_DATE: "date",
    ACTION_VIEW: "view",
    ACTION_CALENDAR: "calendar",
    ACTION_FILTER: "filter",
  },

  sortByAction: {
    SORT_BY_POPULARITY: 1,
    SORT_BY_GENRE: 2,
    SORT_BY_NAMEATOZ: 3,
    SORT_BY_NAMEZTOA: 4,
    SORT_BY_STAGE: 5,
    SORT_BY_RECENT: 6,
    SORT_BY_RATING_HIGH: 7,
    SORT_BY_RATING_LOW: 8,
    SORT_BY_PRICE_HIGH: 9,
    SORT_BY_PRICE_LOW: 10,
    SORT_BY_RECOMMENDED: 11,
    SORT_BY_TRENDING: 12,
    SORT_BY_CONNECTIONS: 13,
    SORT_BY_LINEUP_SIZE: 14,
    SORT_BY_SOCIAL_MEDIA_POPULARITY: 15,
    SORT_BY_LINEUP_POPULARITY: 16,
    SORT_BY_EVENT_COUNT: 17,
    SORT_BY_EVENT_POPULARITY: 18,
    SORT_BY_LATE: 19,
  },

  dateFilterType: {
    DATE_FILTER_UPCOMING: 1,
    DATE_FILTER_PAST: 2,
  },
  viewType: {
    VIEW_TYPE_LIST: "list",
    VIEW_TYPE_GRID: "grid",
    VIEW_TYPE_POSTER: "poster",
    VIEW_TYPE_UPDATES: "updates",
    VIEW_TYPE_EVENTS: "events",
    VIEW_TYPE_COUNTRIES: "countries",
    VIEW_TYPE_ARTISTS: "artists",
    VIEW_TYPE_OVERVIEW: "overview",
    VIEW_TYPE_MAP: "map",
  },
  profileMenuType: {
    PROFILE_MENU_EDITION: "PROFILE_MENU_EDITION",
    PROFILE_MENU_CALENDAR: "PROFILE_MENU_CALENDAR",
    PROFILE_MENU_TICKET_LINK: "PROFILE_MENU_TICKET_LINK",
    PROFILE_MENU_ID_PROFILE: "PROFILE_MENU_ID_PROFILE",
    PROFILE_MENU_SHARE: "PROFILE_MENU_SHARE",
    PROFILE_MENU_EDIT_FESTIVAL: "PROFILE_MENU_EDIT_FESTIVAL",
    PROFILE_MENU_REPORT_MISTAKE: "PROFILE_MENU_REPORT_MISTAKE",
    PROFILE_MENU_CONTACT_HOST: "PROFILE_MENU_CONTACT_HOST",
    PROFILE_MENU_UPDATE: "PROFILE_MENU_UPDATE",
    PROFILE_MENU_REPORT: "PROFILE_MENU_REPORT",
    PROFILE_MENU_INSIGHTS: "PROFILE_MENU_INSIGHTS",
    PROFILE_MENU_BOOKING: "PROFILE_MENU_BOOKING",
    PROFILE_MENU_CALENDAR: "PROFILE_MENU_CALENDAR",
  },
  pickerMenu: {
    TAKE_PHOTO: "TAKE_PHOTO",
    UPLOAD_PHOTO: "UPLOAD_PHOTO",
    REMOVE_PHOTO: "REMOVE_PHOTO",
  },
  profileModalType: {
    PROFILE_MODAL_ID_PROFILE: "PROFILE_MODAL_ID_PROFILE",
    PROFILE_MODAL_REPORT: "PROFILE_MODAL_REPORT",
    PROFILE_MODAL_CONTACT: "PROFILE_MODAL_CONTACT",
    PROFILE_MODAL_CALENDAR: "PROFILE_MODAL_CALENDAR",
    PROFILE_MODAL_BOOKING: "PROFILE_MODAL_BOOKING",
    PROFILE_MODAL_REPORT_MISTAKE: "PROFILE_MODAL_REPORT_MISTAKE",
    PROFILE_MODAL_SHARE: "PROFILE_MODAL_SHARE",
    PROFILE_MODAL_ADD_TICKET_LINK: "PROFILE_MODAL_ADD_TICKET_LINK",
    PROFILE_MODAL_ADD_TO_CALENDAR: "PROFILE_MODAL_ADD_TO_CALENDAR",
  },
  categoryType: {
    CATEGORY_VENUE: 1,
    CATEGORY_EVENT: 2,
    CATEGORY_FESTIVAL: 3,
    CATEGORY_ARTIST: 4,
    CATEGORY_PROMOTERS: 5,
    CATEGORY_OTHER: 6,
    CATEGORY_USER: 6,
  },
  profileTabType: {
    TAB_ABOUT: "about",
    TAB_INSIGHT: "insight",
    TAB_MEDIA: "media",
    TAB_LINEUP: "lineup",
    TAB_EVENT: "events",
    TAB_WALL: "wall",
    TAB_MEMORIES: "memories",
    TAB_ACTIVITY: "activity",
  },
  tagUnit: {
    TAG_UNIT_HOUR: 1,
    TAG_UNIT_AGE: 2,
    TAG_UNIT_CURRENCY_USD: 3,
  },
  postType: {
    POST_TYPE_POST: "wall",
    POST_TYPE_MEMORY: "memory",
  },
  tagCategory: {
    TAG_CATEGORY_DURATION: 1,
    TAG_CATEGORY_STATUS: 2,
    TAG_CATEGORY_EVENT_TYPE: 3,
    TAG_CATEGORY_MUSIC: 4,
    TAG_CATEGORY_PARTICIPATION: 5,
    TAG_CATEGORY_ENTRY: 6,
    TAG_CATEGORY_PRICE: 7,
    TAG_CATEGORY_RATING: 8,
    TAG_CATEGORY_FEATURES: 9,
    TAG_CATEGORY_VENUE: 10,
    TAG_CATEGORY_SIZE: 11,
    TAG_CATEGORY_AREA: 12,
    TAG_CATEGORY_DRESS_CODE: 13,
    TAG_CATEGORY_AGE: 14,
    TAG_CATEGORY_ACCOMODATION: 15,
    TAG_CATEGORY_VENUE_2: 16,
    TAG_CATEGORY_PRICE_VENUE: 17,
    TAG_CATEGORY_SERVICE: 18,
  },
  tagCategoryIcons: {},
  dateFormat: {
    DATE_FORMAT_SIMPLE: "ddd DD MMM",
    DATE_FORMAT_MEDIUM: "DD MMM YYYY",
    DATE_FORMAT_MONTH_SIMPLE: "MMM YYYY",
    DATE_FORMAT_LONG: "ddd, DD MMMM YYYY",
    DATE_TIME_FORMAT_LONG: "ddd DD MMM HH:mm YYYY",
    TIME_FORMAT_SIMPLE: "HH:mm",
    DATE_FORMAT_YEAR: "YYYY",
  },
  locationFormat: {
    LOCATION_FORMAT_STREET_CITY: "LOCATION_FORMAT_STREET_CITY",
    LOCATION_FORMAT_FULL: "LOCATION_FORMAT_FULL",
  },
  homeCategoryTabItems: {},
  actionType: {
    ACTION_FAVORITE: "ACTION_FAVORITE",
    ACTION_SHARE: "ACTION_SHARE",
    ACTION_COMMENT: "ACTION_COMMENT",
    ACTION_ADD: "ACTION_ADD",
    ACTION_SORT: "ACTION_SORT",
    ACTION_PLUS: "ACTION_PLUS",
    ACTION_VIEW: "ACTION_VIEW",
    ACTION_CANCEL: "ACTION_CANCEL",
    ACTION_COPY: "ACTION_COPY",
    ACTION_REPORT_OTHER: "ACTION_REPORT_OTHER",
    ACTION_REPORT: "ACTION_REPORT",
    ACTION_CHAT: "ACTION_CHAT",
    ACTION_CLOSE: "ACTION_CLOSE",
    ACTION_SEND: "ACTION_SEND",
    ACTION_GOOGLE_CALENDAR: "ACTION_GOOGLE_CALENDAR",
    ACTION_OUTLOOK_CALENDAR: "ACTION_OUTLOOK_CALENDAR",
    ACTION_ARTIST: "ACTION_ARTIST",
    ACTION_ATTACH: "ACTION_ATTACH",
    ACTION_DATE: "ACTION_DATE",
    ACTION_DELETE: "ACTION_DELETE",
    ACTION_APPLY: "ACTION_APPLY",
    ACTION_CLEAR_ALL: "ACTION_CLEAR_ALL",
    ACTION_TAG_CITY: "ACTION_TAG_CITY",
    ACTION_SETTING: "ACTION_SETTING",
    ACTION_SAVE: "ACTION_SAVE",
    ACTION_EDIT: "ACTION_EDIT",
    ACTION_CONFIRM: "ACTION_CONFIRM",
    ACTION_ACCOMODATION: "ACTION_ACCOMODATION",
    ACTION_TRANSPORT: "ACTION_TRANSPORT",
    ACTION_MAP: "ACTION_MAP",
    ACTION_CAMERA: "ACTION_CAMERA",
    ACTION_GOING: "going",
    ACTION_SAVING: "saving",
    ACTION_AVATAR: "ACTION_AVATAR",
    ACTION_GOOGLE_MAP: "ACTION_GOOGLE_MAP",
  },
  artistType: {
    ARTIST_TYPE_ELECTRONIC: 1,
    ARTIST_TYPE_HIP_HOP: 2,
    ARTIST_TYPE_POP: 3,
    ARTIST_TYPE_R_B: 4,
  },
  reportCategory: {
    REPORT_POST: "1",
    REPORT_MEMORY: "2",
    REPORT_EVENT: "3",
    REPORT_ARTIST: "4",
    REPORT_VENUE: "5",
    REPORT_FESTIVAL: "6",
    REPORT_USER: "7", //profile
    REPORT_ACCOUNT: "7",
    REPORT_ALBUM: "8",
    REPORT_PHOTO: "9",
    REPORT_VIDEO: "9",
  },
  reportType: {
    photo: 1,
    video: 2,
    post: 3,
    memory: 4,
    artist: 5,
    user: 6,
    event: 7,
    account: 8,
    venue: 9,
  },
  currencyType: {
    EUR: 1,
    POUND: 2,
    USD: 3,
  },
  currencies: [{ id: 3, name: "USD" }],
  mediaUploadState: {
    MEDIA_INIT: "MEDIA_INIT",
    MEDIA_UPLOADING: "MEDIA_UPLOADING",
    MEDIA_READY: "MEDIA_READY",
  },
  OPTION_ALL_ID: -1,
  settingMenuType: {
    MENU_TYPE_ACCOUNT_GENERAL: "MENU_TYPE_ACCOUNT_GENERAL",
    MENU_TYPE_ACCOUNT_PREFERENCES: "MENU_TYPE_ACCOUNT_PREFERENCES",
    MENU_TYPE_NOTIFICATIONS: "MENU_TYPE_NOTIFICATIONS",
    MENU_TYPE_PRIVACY: "MENU_TYPE_PRIVACY",
    MENU_TYPE_LANGUAGE: "MENU_TYPE_LANGUAGE",
    MENU_TYPE_DISTANT_UNITS: "MENU_TYPE_DISTANT_UNITS",
    MENU_TYPE_CHANGE_PASSWORD: "MENU_TYPE_CHANGE_PASSWORD",
    MENU_TYPE_DELETE_ACCOUNT: "MENU_TYPE_DELETE_ACCOUNT",
    MENU_TYPE_HELP_CENTER: "MENU_TYPE_HELP_CENTER",
    MENU_TYPE_CONTRIBUTE: "MENU_TYPE_CONTRIBUTE",
    MENU_TYPE_REPORT_PROBLEM: "MENU_TYPE_REPORT_PROBLEM",
    MENU_TYPE_ABOUT: "MENU_TYPE_ABOUT",
    MENU_TYPE_RATE_APP: "MENU_TYPE_RATE_APP",
    MENU_TYPE_ADD_FRIENDS: "MENU_TYPE_ADD_FRIENDS",
    MENU_TYPE_SHARE_APP: "MENU_TYPE_SHARE_APP",
    MENU_TYPE_COMMUNITY_GUIDELINES: "MENU_TYPE_COMMUNITY_GUIDELINES",
    MENU_TYPE_TERMS_OF_USE: "MENU_TYPE_TERMS_OF_USE",
    MENU_TYPE_DISCLAIMER: "MENU_TYPE_DISCLAIMER",
    MENU_TYPE_EULA: "MENU_TYPE_EULA",
    MENU_TYPE_PRIVACY_POLICY: "MENU_TYPE_PRIVACY_POLICY",
    MENU_TYPE_COPYRIGHT_POLICY: "MENU_TYPE_COPYRIGHT_POLICY",
    MENU_TYPE_LOGOUT: "MENU_TYPE_LOGOUT",
  },
  socialType: {
    SOCIAL_TYPE_FACEBOOK: "facebook",
    SOCIAL_TYPE_INSTAGRAM: "instagram",
    SOCIAL_TYPE_TWITTER: "twitter",
    SOCIAL_TYPE_YOUTUBE: "youtube",
    SOCIAL_TYPE_SPOTIFY: "spotify",
    SOCIAL_TYPE_LINK: "link",
  },
  distanceUnit: {
    DISTANCE_UNIT_MILE: 1,
    DISTANCE_UNIT_KM: 2,
  },
  replyType: {
    REPLY_TYPE_EVERYONE: 1,
    REPLY_TYPE_FOLLOWERS: 2,
    REPLY_TYPE_FOLLOWINGS: 3,
    REPLY_TYPE_DISABLE: 4,
  },
  albumType: {
    MIX: 1,
  },
  transportUrl: "https://omio.it",
  storageKey: {
    STOREAGE_KEY_CREDENTICAL: "STOREAGE_KEY_CREDENTICAL",
    STOREAGE_KEY_REMEMBER_ME: "STOREAGE_KEY_REMEMBER_ME",
    STOREAGE_KEY_ACCESS_TOKEN: "STOREAGE_KEY_ACCESS_TOKEN",
    STOREAGE_KEY_AUTH: "auth",
  },
  mapStyleType: {
    MAP_STYLE_GRAY: "MAP_STYLE_GRAY",
  },
  mediaSourceType: {
    MEDIA_SOURCE_GALLERY: "Gallery",
    MEDIA_SOURCE_CAMERA: "Camera",
    MEDIA_SOURCE_SCREENSHOTS: "Screenshots",
    MEDIA_SOURCE_DOWNLOADS: "Downloads",
    MEDIA_SOURCE_INSTAGRAM: "Instagram",
    MEDIA_SOURCE_FACEBOOK: "Facebook",
    MEDIA_SOURCE_ADOBE_LIGHTROOM: "Adobe Lightroom",
  },
  filterConnectedProfileLogic: {
    FILTER_PROFILE_LOGIC_GOING: 1,
    FILTER_PROFILE_LOGIC_SAVED: 2,
    FILTER_PROFILE_LOGIC_RELACTED_CONNECT: 3,
    FILTER_PROFILE_LOGIC_FOLLOWER: 4,
    FILTER_PROFILE_LOGIC_FOLLOWING: 5,
    FILTER_PROFILE_LOGIC_TOGETHER_FOLLOWING: 6,
    FILTER_PROFILE_LOGIC_CONNECT: 7,
  },
  privacyType: {
    private: "private",
    public: "public",
  },
  festivalStatus: {
    FE_STAT_UPCOMMING_CONFIRMED: 1,
    FE_STAT_UPCOMMING_TO_BE_UPDATED: 2,
    FE_STAT_UPCOMMING_LIVE: 3,
    FE_STAT_UPCOMMING_CANCELED: 4,
    FE_STAT_RESCHEDULE_TO_BE_UPDATED: 5,
    FE_STAT_PAST: 6,
  },
  venueStatus: {
    FE_STAT_OPEN: 1,
    FE_STAT_CLOSE: 2,
    FE_STAT_PERMANENTLY_CLOSED: 3,
    FE_STAT_TEMPORARILY_CLOSED: 4,
  },
  artistActivityType: {
    ARTIST_ACTIVITY_TYPE_IN_TOUR: 1,
    ARTIST_ACTIVITY_TYPE_IN_ACTIVE: 2,
  },
  commentContentType: {
    COMMENT_CONTENT_TYPE_POST: 1,
    COMMENT_CONTENT_TYPE_MEDIA: 2,
  },
  artistActivity: {
    ACTIVITY_IN_TOUR: 1,
    ACTIVITY_IN_ACTIVE: 2,
  },
  spotifyMatchOptions: {
    SPOTIFY_MATCH_FOLLOWED: "followed",
    SPOTIFY_MATCH_FAVORITE_LONG_TERM: "favourite-long_term",
    SPOTIFY_MATCH_FAVORITE_MEDIUM_TERM: "favourite-medium_term",
    SPOTIFY_MATCH_FAVORITE_SHORT_TERM: "favourite-short_term",
  },
  PAGE_H_PADDING: (Values.deviceWidth - 1070) / 2,
  PROFILE_PAGE_WIDTH: 1070,
  VISIT_APP_LINK: "https://linktr.ee/Soundclub20",
  FESTIVAL_EDIT_URL: "https://www.soundclub.com/contribute",
};
