import { createStore } from "redux";
import { persistStore } from "redux-persist";
import { createWrapper } from "next-redux-wrapper";
import { devToolsEnhancer } from "redux-devtools-extension";

import reducer from "./reducer";

export const store = createStore(reducer, devToolsEnhancer());
const makeStore = () => store;
export const wrapper = createWrapper(makeStore, { debug: true });
export const persistor = persistStore(store);
