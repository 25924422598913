import Moment from "moment";
import { Constants } from "../config";
import { calculateMilisecondDiff } from "./dateHelpers";

export function formatDate(dateString, formatString = "YYYY-MM-DD", isUtc) {
  if (!dateString) {
    return "-";
  }
  const momentObj = Moment(dateString);
  let momentString = momentObj.format(formatString);
  if (isUtc) {
    momentString = momentObj.utc().format(formatString);
  }
  if (momentString == "Invalid date") return "-";
  return momentString;
}
export function getDateString(date) {
  if (!date) return null;
  return date.substring(0, 10);
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function pluralFormat(string = "days", amount) {
  return amount > 1 ? string : string.substring(0, string.length - 1);
}

export function diffFormat(startDate, endDate, type = "days") {
  const delta = calculateMilisecondDiff(startDate, endDate)
  if (delta > 86400000 * 2) {
    return "+3 Days";
  }
  if (delta > 86400000) {
    return "2 Days";
  }
  if (delta > 3600000 * 6) {
    return "6h-1 Day";
  }
  if (delta > 3600000 * 3) {
    return "3h-6h";
  }
  return "0-3h";
}

export function formatCurrency(currency, currencyType) {
  let currencySymbol = "$";
  if (currencyType == Constants.currencyType.POUND) {
    currencySymbol = "£";
  } else if (currencyType == Constants.currencyType.EUR) {
    currencyType = "€";
  }
  if (!currency) return "-";
  return `${currencySymbol}${currency}`;
}

export function formatNumber(count) {
  if (count > 1000000) {
    const milions = (count / 1000000).toFixed(1);
    return milions + "m";
  }
  if (count > 1000) {
    const thousands = Math.floor(count / 1000);
    return thousands + "k";
  }
  if (count === 0 || count === "0" || !count) {
    return "-";
  }
  return count;
}

export function formatPeriod(
  startDate,
  endDate,
  formatString = "YYYY-MM-DD",
  timezone = ""
) {
  return (
    formatDate(startDate, formatString, true) +
    " - " +
    formatDate(endDate, formatString, true) +
    " " +
    timezone
  );
}
export function getDisplayName(profile) {
  if (!profile) return;
  if (profile.displayName) return profile.displayName;
  if (profile.nickname) return profile.nickname;
  if (profile.firstName || profile.lastName)
    return profile.firstName + " " + profile.lastName;
  if (profile.username) return profile.username;
  return "User";
}

export function formatTime(s) {
  // Hours, minutes and seconds
  return (s - (s %= 60)) / 60 + (9 < s ? ":" : ":0") + s;
}

export function getSpecialist(genres) {
  if (!genres) return "";
  if (genres.length == 0) return "";
  if (genres.length > 1) {
    return (genres[0].title || genres[0]) + " +" + (genres.length - 1);
  }
  if (genres.length == 1) {
    return genres[0].title || genres[0];
  }
  return "";
}
