import {
  showAlert,
  showAlertWithCallback,
  confirmAlertWithCallback,
  showAlertWithQuestionCallback,
} from './alert'
import { jwtDecode } from './jwtHelper'
import {
  emailValidate,
  passwordValidate,
  phoneNumberValidate,
} from './validator'
import { formatDate, formatNumber } from './formatHelpers'
import {
  calculateMilisecondDiff,
  getPeriod,
  formatPeriod,
  getPeriodString,
} from './dateHelpers'
import { getCredential, saveCredential } from './storageHelper'
import { toggleItemInItems } from './arrayHelpers'
import parseURLScheme from './parseURLScheme'
export {
  showAlert,
  showAlertWithCallback,
  confirmAlertWithCallback,
  showAlertWithQuestionCallback,
  emailValidate,
  passwordValidate,
  phoneNumberValidate,
  formatDate,
  calculateMilisecondDiff,
  getPeriodString,
  getPeriod,
  formatNumber,
  jwtDecode,
  saveCredential,
  getCredential,
  formatPeriod,
  toggleItemInItems,
  parseURLScheme,
}
