import { Constants } from '../../../../config'
import * as ACTION_TYPES from '../actions/ActionTypes'
const INITIAL_STATE = {
  selectedMediaSource: Constants.mediaSourceType.MEDIA_SOURCE_GALLERY,
  selectedMediaItems: [],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPES.MEDIA_PICKER_SET_SELECTED_MEDIA_SOURCE:
      return { ...state, selectedMediaSource: action.mediaSource }
    case ACTION_TYPES.SET_SELECTED_MEDIA_ITEMS:
      return { ...state, selectedMediaItems: action.items }
    default:
      return state
  }
}
