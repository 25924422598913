import * as React from "react";
// import { CommonActions } from '@react-navigation/native'

export const navigationRef = React.createRef();

export function navigate(name, params) {
  navigationRef.current?.navigate(name, params);
}

export function navigateWithKey(name, params, key) {
  navigationRef.current?.navigate({ name, params, key });
}

export function reset(name) {
  // const resetAction = CommonActions.reset({
  //   index: 0,
  //   routes: [{ name }],
  // })
  // navigationRef.current?.dispatch(resetAction)
}
