import storage from "redux-persist/lib/storage";
import * as ACTION_TYPES from "../actions/ActionTypes";
import { jwtDecode } from "../../../../helpers";

const INITIAL_STATE = {
  isLoading: false,
  success: false,
  error: null,
  currentAction: null,
  accessToken: null,
  getStreamToken: null,
  refreshToken: null,
  userId: null,
  isInitialized: false,
  visitedWelcome: false,
  initializeData: null,
  isSuccess: null,
  messages: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_TYPES.VISIT_WELCOME:
      return { ...state, visitedWelcome: true };
    case ACTION_TYPES.SOCIAL_AUTH_CONNECT:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: null,
        currentAction: action.type,
      };
    case ACTION_TYPES.SOCIAL_AUTH_CONNECT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        error: null,
        currentAction: action.type,
      };
    case ACTION_TYPES.AUTH:
      return {
        ...state,
        isLoading: true,
        credential: action.credential,
        success: false,
        error: null,
        currentAction: action.type,
      };
    case ACTION_TYPES.SET_NAVIGATE_TO_MAIN:
      return {
        ...state,
      };
    case ACTION_TYPES.AUTH_SUCCESS:
      var jwtPayload = jwtDecode(action.auth.accessToken);
      return {
        ...state,
        accessToken: action.auth.accessToken,
        getStreamToken: action.auth.getStreamToken,
        refreshToken: action.auth.refreshToken || null,
        userId: jwtPayload.userId || null,
        role: jwtPayload.role || null,
        needsAuth: false,
        isLoading: false,
        success: true,
        error: null,
        currentAction: action.type,
        isInitialized:
          action.auth.user &&
          action.auth.user.phoneNumber &&
          action.auth.user.email
            ? true
            : false,
        user: action.auth.user,
      };

    case ACTION_TYPES.AUTH_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: "An error occured",
        success: false,
        currentAction: action.type,
      };

    case ACTION_TYPES.AUTH_UNAUTHORIZED:
      storage.removeItem("auth").then((result) => {});
      return INITIAL_STATE;

    case ACTION_TYPES.EMAIL_VERIFY:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: null,
      };

    case ACTION_TYPES.EMAIL_VERIFY_SUCCESS:
      return {
        isLoading: false,
        success: true,
        error: null,
      };

    case ACTION_TYPES.EMAIL_VERIFY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error ? action.error : "Unexpected Error",
        success: false,
      };

    case ACTION_TYPES.REGISTER:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: null,
        currentAction: action.type,
      };

    case ACTION_TYPES.REGISTER_SUCCESS:
      return {
        isLoading: false,
        success: true,
        error: null,
        currentAction: action.type,
      };

    case ACTION_TYPES.REGISTER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error ? action.error : "Unexpected Error",
        success: false,
        currentAction: action.type,
      };

    case ACTION_TYPES.INITIALIZE_ME:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: null,
        currentAction: action.type,
      };
    case ACTION_TYPES.INITIALIZE_ME_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        error: null,
        currentAction: action.type,
        initializeData: action.data,
      };
    case ACTION_TYPES.INITIALIZE_ME_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: "An error occured",
        success: false,
        currentAction: action.type,
      };

    case ACTION_TYPES.AUTH_STRATEGY_DESTROY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        error: null,
        currentAction: action.type,
      };

    case ACTION_TYPES.LOGOUT:
      return INITIAL_STATE;

    case ACTION_TYPES.GET_AUTH:
      var jwtPayload = jwtDecode(action.payload.accessToken);
      return {
        ...state,
        accessToken: action.payload.accessToken,
        getStreamToken: action.payload.getStreamToken,
        userId: jwtPayload.userId || null,
        role: jwtPayload.role || null,
        needsAuth: false,
        isLoading: false,
        success: true,
        error: null,
        currentAction: action.type,
        isInitialized:
          action.payload.user &&
          action.payload.user.phoneNumber &&
          action.payload.user.email
            ? true
            : false,
        user: action.payload.user,
      };

    case ACTION_TYPES.FORGOT_PASSWORD:
      return {
        ...state,
        isLoading: true,
        success: false,
        error: null,
        currentAction: action.type,
      };
    case ACTION_TYPES.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        error: null,
        currentAction: action.type,
      };
    case ACTION_TYPES.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.data.message,
        success: false,
        currentAction: action.type,
      };

    default:
      return state;
  }
};
